import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { OrganizationEvent } from '../models/organization-event';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationEventService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of events for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters to filter events.
   * 
   * @returns {Observable<{ data: OrganizationEvent[], meta: any }>}
   */
  getOrganizationEvents(organizationId: string, params: any = {}): Observable<{ data: OrganizationEvent[], meta: any }> {
    return this.apiService.get(`organizations/${organizationId}/events`, params);
  }

  /**
   * Retrieves the specified event.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} eventId - The ID of the event.
   * @param {object} params - Optional parameters modify the response.
   * 
   * @returns {Observable<OrganizationEvent>}
   */
  getOrganizationEvent(organizationId: string, eventId: string, params: any = {}): Observable<OrganizationEvent> {
    return this.apiService.get(`organizations/${organizationId}/events/${eventId}`, params);
  }

  /**
   * Updates the specified event for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} eventId - The ID of the event.
   * @param {object} params - The data to update the event.
   * 
   * @returns {Observable<{ data: OrganizationEvent, meta: any }>}
   */
  updateOrganizationEvent(organizationId: string, eventId: string, params: any = {}): Observable<{ data: OrganizationEvent, meta: any }> {
    return this.apiService.put(`organizations/${organizationId}/events/${eventId}`, params);
  }

  /**
   * Creates a new event for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - The data to create the event.
   * 
   * @returns {Observable<{ data: OrganizationEvent, meta: any }>}
   */
  createOrganizationEvent(organizationId: string, params: any = {}): Observable<{ data: OrganizationEvent, meta: any }> {
    return this.apiService.post(`organizations/${organizationId}/events`, params);
  }

  /**
   * Deletes the specified event for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} eventId - The ID of the event to be deleted.
   * 
   * @returns {Observable<any>}
   */
  deleteOrganizationEvent(organizationId: string, eventId: string): Observable<any> {
    return this.apiService.delete(`organizations/${organizationId}/events/${eventId}`);
  }

  /**
   * Retrieves a list of events types.
   * 
   * @param {object} params - Optional parameters to filter events.
   * 
   * @returns {Observable<{ data: any[], meta: any }>}
   */
  getOrganizationEventTypes(params: any = {}): Observable<{ data: any[], meta: any }> {
    return this.apiService.get(`public/event-types`, params);
  }

  /**
   * Uploads a logo for the specified event.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} eventId - The ID of the event.
   * @param {object} params - The logo file to upload.
   * 
   * @returns {Observable<any>}
   */
  uploadOrganizationEventLogo(organizationId: string, eventId: string, params: any = {}): Observable<any> {
    return this.apiService.upload(`organizations/${organizationId}/events/${eventId}/logo`, params);
  }

  /**
   * Deletes the logo for the specified event.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} eventId - The ID of the event.
   * @param {object} params - Optional parameters.
   * 
   * @returns {Observable<any>}
   */
  deleteOrganizationEventLogo(organizationId: string, eventId: string, params: any = {}): Observable<any> {
    return this.apiService.delete(`organizations/${organizationId}/events/${eventId}/logo`, params);
  }

}
