import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { OrganizationChannel } from '../models/organization-channel';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationChannelService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of channels for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters to filter channels.
   * 
   * @returns {Observable<{ data: OrganizationChannel[], meta: any }>}
   */
  getOrganizationChannels(organizationId: string, params: any = {}): Observable<{ data: OrganizationChannel[], meta: any }> {
    return this.apiService.get(`organizations/${organizationId}/channels`, params);
  }

  /**
   * Retrieves the specified channel.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} channelId - The ID of the channel.
   * @param {object} params - Optional parameters modify the response.
   * 
   * @returns {Observable<{ data: OrganizationChannel, meta: any }>}
   */
  getOrganizationChannel(organizationId: string, channelId: string, params: any = {}): Observable<{ data: OrganizationChannel, meta: any }> {
    return this.apiService.get(`organizations/${organizationId}/channels/${channelId}`, params);
  }

  /**
   * Updates the specified channel for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} channelId - The ID of the channel.
   * @param {object} params - The data to update the channel.
   * 
   * @returns {Observable<{ data: OrganizationChannel, meta: any }>}
   */
  updateOrganizationChannel(organizationId: string, channelId: string, params: any = {}): Observable<{ data: OrganizationChannel, meta: any }> {
    return this.apiService.put(`organizations/${organizationId}/channels/${channelId}`, params);
  }

  /**
   * Creates a new channel for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - The data to create the channel.
   * 
   * @returns {Observable<{ data: OrganizationChannel, meta: any }>}
   */
  createOrganizationChannel(organizationId: string, params: any = {}): Observable<{ data: OrganizationChannel, meta: any }> {
    return this.apiService.post(`organizations/${organizationId}/channels`, params);
  }

  /**
   * Deletes the specified channel for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} channelId - The ID of the channel to be deleted.
   * 
   * @returns {Observable<any>}
   */
  deleteOrganizationChannel(organizationId: string, channelId: string): Observable<any> {
    return this.apiService.delete(`organizations/${organizationId}/channels/${channelId}`);
  }

  /**
   * Uploads a logo for the specified channel.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} channelId - The ID of the channel.
   * @param {object} params - The logo file to upload.
   * 
   * @returns {Observable<any>}
   */
  uploadOrganizationChannelLogo(organizationId: string, channelId: string, params: any = {}): Observable<any> {
    return this.apiService.upload(`organizations/${organizationId}/channels/${channelId}/logo`, params);
  }

  /**
   * Deletes the logo for the specified channel.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} channelId - The ID of the channel.
   * @param {object} params - Optional parameters.
   * 
   * @returns {Observable<any>}
   */
  deleteOrganizationChannelLogo(organizationId: string, channelId: string, params: any = {}): Observable<any> {
    return this.apiService.delete(`organizations/${organizationId}/channels/${channelId}/logo`, params);
  }

}
