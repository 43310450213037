import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { OrganizationOffer } from '../models/organization-offer';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationOfferService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of offers for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters to filter offers.
   * 
   * @returns {Observable<{ data: OrganizationOffer[], meta: any }>}
   */
  getOrganizationOffers(organizationId: string, params: any = {}): Observable<{ data: OrganizationOffer[], meta: any }> {
    return this.apiService.get(`organizations/${organizationId}/offers`, params);
  }

  /**
   * Retrieves the specified offer.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} offerId - The ID of the offer.
   * @param {object} params - Optional parameters modify the response.
   * 
   * @returns {Observable<{ data: OrganizationOffer, meta: any }>}
   */
  getOrganizationOffer(organizationId: string, offerId: string, params: any = {}): Observable<{ data: OrganizationOffer, meta: any }> {
    return this.apiService.get(`organizations/${organizationId}/offers/${offerId}`, params);
  }

  /**
   * Updates the specified offer for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} offerId - The ID of the offer.
   * @param {object} params - The data to update the offer.
   * 
   * @returns {Observable<{ data: OrganizationOffer, meta: any }>}
   */
  updateOrganizationOffer(organizationId: string, offerId: string, params: any = {}): Observable<{ data: OrganizationOffer, meta: any }> {
    return this.apiService.put(`organizations/${organizationId}/offers/${offerId}`, params);
  }

  /**
   * Creates a new offer for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - The data to create the offer.
   * 
   * @returns {Observable<{ data: OrganizationOffer, meta: any }>}
   */
  createOrganizationOffer(organizationId: string, params: any = {}): Observable<{ data: OrganizationOffer, meta: any }> {
    return this.apiService.post(`organizations/${organizationId}/offers`, params);
  }

  /**
   * Deletes the specified offer for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} offerId - The ID of the offer to be deleted.
   * 
   * @returns {Observable<any>}
   */
  deleteOrganizationOffer(organizationId: string, offerId: string): Observable<any> {
    return this.apiService.delete(`organizations/${organizationId}/offers/${offerId}`);
  }

  /**
   * Uploads a logo for the specified offer.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} offerId - The ID of the offer.
   * @param {object} params - The logo file to upload.
   * 
   * @returns {Observable<any>}
   */
  uploadOrganizationOfferLogo(organizationId: string, offerId: string, params: any = {}): Observable<any> {
    return this.apiService.upload(`organizations/${organizationId}/offers/${offerId}/logo`, params);
  }

  /**
   * Deletes the logo for the specified offer.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} offerId - The ID of the offer.
   * @param {object} params - Optional parameters.
   * 
   * @returns {Observable<any>}
   */
  deleteOrganizationOfferLogo(organizationId: string, offerId: string, params: any = {}): Observable<any> {
    return this.apiService.delete(`organizations/${organizationId}/offers/${offerId}/logo`, params);
  }

}
