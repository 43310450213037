import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Industry } from '../models/industry';

@Injectable()
export class IndustryService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * @param {any} [params] Params to filter countries
   * 
   * @returns {Observable<{ data: Industry[], meta: any }>}
   */
  getPublicIndustries(params: any = {}): Observable<{ data: Industry[], meta: any }> {
    return this.apiService
      .get(`public/industries`, params);
  }

}
