import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { OrganizationBrochure } from '../models/organization-brochure';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationBrochureService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of brochures for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters to filter brochures.
   * 
   * @returns {Observable<{ data: OrganizationBrochure[], meta: any }>}
   */
  getOrganizationBrochures(organizationId: string, params: any = {}): Observable<{ data: OrganizationBrochure[], meta: any }> {
    return this.apiService.get(`organizations/${organizationId}/sponsorship-brochures`, params);
  }

  /**
   * Retrieves the specified brochure.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} brochureId - The ID of the brochure.
   * @param {object} params - Optional parameters modify the response.
   * 
   * @returns {Observable<{ data: OrganizationBrochure, meta: any }>}
   */
  getOrganizationBrochure(organizationId: string, brochureId: string, params: any = {}): Observable<OrganizationBrochure> {
    return this.apiService.get(`organizations/${organizationId}/sponsorship-brochures/${brochureId}`, params);
  }

  /**
   * Updates the specified brochure for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} brochureId - The ID of the brochure.
   * @param {object} params - The data to update the brochure.
   * 
   * @returns {Observable<{ data: OrganizationBrochure, meta: any }>}
   */
  updateOrganizationBrochure(organizationId: string, brochureId: string, params: any = {}): Observable<OrganizationBrochure> {
    return this.apiService.upload(`organizations/${organizationId}/sponsorship-brochures/${brochureId}`, params);
  }

  /**
   * Creates a new brochure for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - The data to create the brochure.
   * 
   * @returns {Observable<{ data: OrganizationBrochure, meta: any }>}
   */
  createOrganizationBrochure(organizationId: string, params: any = {}): Observable<OrganizationBrochure> {
    return this.apiService.upload(`organizations/${organizationId}/sponsorship-brochures`, params);
  }

  /**
   * Deletes the specified brochure for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} brochureId - The ID of the brochure to be deleted.
   * 
   * @returns {Observable<any>}
   */
  deleteOrganizationBrochure(organizationId: string, brochureId: string): Observable<any> {
    return this.apiService.delete(`organizations/${organizationId}/sponsorship-brochures/${brochureId}`);
  }

  /**
   * Downloads the specified brochure for the specified organization.
   * 
   * @param {string} path - Path to the brochure.
   * @param {string} filename - Filename of the brochure.
   * 
   * @returns {Observable<any>}
   */
  downloadOrganizationBrochure(path: string, filename: string): Observable<any> {
    return this.apiService.download(path, filename);
  }

  uploadOrganizationBrochureFile(organizationId: string, brochureId: string, params: any = {}) {
    return this.apiService.upload(`organizations/${organizationId}/sponsorship-brochures/${brochureId}/file`, params);
  }

  deleteOrganizationBrochureFile(organizationId: string, brochureId: string, params: any = {}) {
    return this.apiService.delete(`organizations/${organizationId}/sponsorship-brochures/${brochureId}/file`, params);
  }

}
