import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Provider } from '../models/provider';

@Injectable()
export class ProviderService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of providers.
   * 
   * @param {any} [params] - Optional parameters to filter providers
   * 
   * @returns {Observable<{ data: Provider[], meta: any }>}
   */
  getPublicProviders(params: any = {}): Observable<{ data: Provider[], meta: any }> {
    return this.apiService
      .get(`public/providers`, params);
  }

}
