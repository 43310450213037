
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecaptchaModule, RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service } from "ng-recaptcha-2";
import { RiterzCoreConfiguration } from './riterz-core.configuration';
import { UserService } from './services/user.service';
import { ApiService } from './services/api.service';
import { InvitationService } from './services/invitation.service';
import { OrganizationService } from './services/organization.service';
import { RecaptchaService } from './services/recaptcha.service';
import { AuditLogService } from './services/audit-log.service';
import { OrganizationCampaignService } from './services/organization-campaign.service';
import { OrganizationCampaignAdService } from './services/organization-campaign-ad.service';
import { NotificationService } from './services/notification.service';
import { OrganizationAdvertiserService } from './services/organization-advertiser.service';
import { OrganizationEventService } from './services/organization-event.service';
import { OrganizationProviderService } from './services/organization-provider.service';
import { TutorialService } from './services/tutorial.service';
import { CountryService } from './services/country.service';
import { TimeZoneService } from './services/timezone.service';
import { CurrencyService } from './services/currency.service';
import { OrganizationPublisherService } from './services/organization-publisher.service';
import { TopicService } from './services/topic.service';
import { IndustryService } from './services/industry.service';
import { OrganizationChannelService } from './services/organization-channels.service';
import { OrganizationOfferService } from './services/organization-offer.service';
import { CookieService } from './services/cookie.service';
import { ProviderService } from './services/provider.service';
import { ChannelService } from './services/channel.service';
import { OrganizationRoleService } from './services/organization-role.service';
import { OrganizationBrochureService } from './services/organization-brochures.service';
import { OrganizationTopicService } from './services/organization-topic.service';

@NgModule({
  imports: [
    CommonModule,
    RecaptchaModule,
  ]
})
export class RiterzCoreModule {
  public static forRoot(
    configuration: RiterzCoreConfiguration
  ) {
    return {
      ngModule: RiterzCoreModule,
      providers: [
        {
          provide: RiterzCoreConfiguration,
          useValue: configuration,
        },
        { 
          provide: RECAPTCHA_V3_SITE_KEY, 
          useValue: configuration.recaptchaSiteKey
        },
        RecaptchaService,
        ReCaptchaV3Service,
        ApiService,
        OrganizationService,
        CookieService,
        ChannelService,
        OrganizationCampaignService,
        OrganizationCampaignAdService,
        OrganizationAdvertiserService,
        OrganizationPublisherService,
        OrganizationEventService,
        OrganizationProviderService,
        OrganizationChannelService,
        OrganizationOfferService,
        OrganizationBrochureService,
        OrganizationRoleService,
        OrganizationTopicService,
        ProviderService,
        UserService,
        InvitationService,
        AuditLogService,
        NotificationService,
        TutorialService,
        CountryService,
        TimeZoneService,
        CurrencyService,
        TopicService,
        IndustryService
      ]
    };
  }

  public static forChild(): ModuleWithProviders<RiterzCoreModule> {
    return {
      ngModule: RiterzCoreModule
    };
  }
}
