import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

import { RiterzCoreConfiguration } from '../riterz-core.configuration';
import { Observable, of } from 'rxjs';
import { Organization } from '../models/organization';

@Injectable()
export class OrganizationService {

  constructor(
    private readonly configuration: RiterzCoreConfiguration,
    private apiService: ApiService
  ) { }

  getMyOrganizations(params: any = {}) {
    return this.apiService.get(`users/me/organizations`, params);
  }

  createMyOrganization(params: any = {}) {
    return this.apiService.post(`users/me/organizations`, params);
  }

  getOrganizations(params: any = {}) {
    return this.apiService.get(`organizations`, params);
  }

  getOrganization(organizationId: string, params: any = {}) {
    return this.apiService.get(`organizations/${organizationId}`, params);
  }

  updateOrganization(organizationId: string, params: any = {}) {
    return this.apiService.put(`organizations/${organizationId}`, params);
  }

  createOrganization(params: any = {}) {
    return this.apiService.post(`organizations`, params);
  }

  deleteOrganization(organizationId: string) {
    return this.apiService.delete(`organizations/${organizationId}`);
  }

  getAdminOrganizations(params: any = {}) {
    return this.apiService.get(`admin/organizations`, params);
  }

  getAdminOrganization(organizationId: string, params: any = {}) {
    return this.apiService.get(`admin/organizations/${organizationId}`, params);
  }

  updateAdminOrganization(organizationId: string, params: any = {}) {
    return this.apiService.put(`admin/organizations/${organizationId}`, params);
  }

  createAdminOrganization(params: any = {}) {
    return this.apiService.post(`admin/organizations`, params);
  }

  deleteAdminOrganization(organizationId: string) {
    return this.apiService.delete(`admin/organizations/${organizationId}`);
  }

  createAdvertiser(organizationId: string, params: any = {}) {
    return this.apiService.post(`organizations/${organizationId}/advertisers`, params);
  }

  deleteDemoData(organizationId: string, params: any = {}) {
    return this.apiService.delete(`organizations/${organizationId}/delete-demo-organization-data`, params);
  }

  uploadOrganizationLogo(organizationId: string, params: any = {}) {
    return this.apiService.upload(`organizations/${organizationId}/logo`, params);
  }

  deleteOrganizationLogo(organizationId: string, params: any = {}) {
    return this.apiService.delete(`organizations/${organizationId}/logo`, params);
  }

  /**
   * Claims an organization as a user's own. 
   *
   * @param {string} organizationId - The ID of the organization to be claimed.
   * @param {object} params - Optional parameters.
   * 
   * @returns {Observable<any>}
   */
  claimOrganization(organizationId: string, params: any = {}): Observable<any> {
    return this.apiService
      .post(`organizations/${organizationId}/claim`, params);
  }

  /**
   * Retrieves a list of featured organizations.
   *
   * @param {object} params - Optional parameters to filter featured organizations.
   * 
   * @returns {Observable<{ data: Organization[], meta: any }>}
   */
  getPublicFeaturedOrganizations(params: any = {}): Observable<{ data: Organization[], meta: any }> {
    return this.apiService
      .get(`public/organizations/featured`, params);
  }

  /**
   * Retrieves a list of organizations.
   *
   * @param {object} params - Optional parameters to filter organizations.
   * 
   * @returns {Observable<{ data: Organization[], meta: any }>}
   */
  getPublicOrganizations(params: any = {}): Observable<{ data: Organization[], meta: any }> {
    return this.apiService
      .get(`public/organizations`, params);
  }

  /**
   * Retrieves the specified organization.
   *
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters modify the response.
   * 
   * @returns {Observable<Organization>}
   */
  getPublicOrganization(organizationId: string, params: any = {}): Observable<Organization> {
    return this.apiService
      .get(`public/organizations/${organizationId}`, params);
  }

}
