import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UserRole } from '../models/user-role';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationRoleService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of roles for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters to filter roles.
   * 
   * @returns {Observable<{ data: UserRole[], meta: any }>}
   */
  getOrganizationRoles(organizationId: string, params: any = {}): Observable<{ data: UserRole[], meta: any }> {
    return this.apiService.get(`organizations/${organizationId}/roles`, params);
  }

  /**
   * Retrieves the specified role.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} roleId - The ID of the role.
   * @param {object} params - Optional parameters modify the response.
   * 
   * @returns {Observable<{ data: UserRole, meta: any }>}
   */
  getOrganizationRole(organizationId: string, roleId: string, params: any = {}): Observable<{ data: UserRole, meta: any }> {
    return this.apiService.get(`organizations/${organizationId}/roles/${roleId}`, params);
  }

  /**
   * Updates the specified role for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} roleId - The ID of the role.
   * @param {object} params - The data to update the role.
   * 
   * @returns {Observable<{ data: UserRole, meta: any }>}
   */
  updateOrganizationRole(organizationId: string, roleId: string, params: any = {}): Observable<{ data: UserRole, meta: any }> {
    return this.apiService.put(`organizations/${organizationId}/roles/${roleId}`, params);
  }

  /**
   * Creates a new role for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - The data to create the role.
   * 
   * @returns {Observable<{ data: UserRole, meta: any }>}
   */
  createOrganizationRole(organizationId: string, params: any = {}): Observable<{ data: UserRole, meta: any }> {
    return this.apiService.post(`organizations/${organizationId}/roles`, params);
  }

  /**
   * Deletes the specified role for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} roleId - The ID of the role to be deleted.
   * 
   * @returns {Observable<any>}
   */
  deleteOrganizationRole(organizationId: string, roleId: string): Observable<any> {
    return this.apiService.delete(`organizations/${organizationId}/roles/${roleId}`);
  }

}
