import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuditLogService {

  constructor(
    private apiService: ApiService
  ) { }
  
  /**
   * Retrieves audit logs.
   * 
   * @param  {string} objectType - Auditable object type
   * @param  {string} objectId - Auditable object id
   * @param  {any} [params] - Optional parameters to filter log entries
   * 
   * @returns {Observable<any>}
   */
  getAuditLogs(objectType: string, objectId: string, params: any = {}): Observable<any> {
    return this.apiService
      .get(`audit-logs/${objectType}/${objectId}`, params);
  }

}
