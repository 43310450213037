import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Topic } from '../models/topic';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationTopicService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of topics for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters to filter topics.
   * 
   * @returns {Observable<{ data: Topic[], meta: any }>}
   */
  getOrganizationTopics(organizationId: string, params: any = {}): Observable<{ data: Topic[], meta: any }> {
    return this.apiService.get(`organizations/${organizationId}/topics`, params);
  }

  /**
   * Retrieves the specified topic.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} topicId - The ID of the topic.
   * @param {object} params - Optional parameters modify the response.
   * 
   * @returns {Observable<{ data: Topic, meta: any }>}
   */
  getOrganizationTopic(organizationId: string, topicId: string, params: any = {}): Observable<{ data: Topic, meta: any }> {
    return this.apiService.get(`organizations/${organizationId}/topics/${topicId}`, params);
  }

  /**
   * Updates the specified topic for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} topicId - The ID of the topic.
   * @param {object} params - The data to update the topic.
   * 
   * @returns {Observable<{ data: Topic, meta: any }>}
   */
  updateOrganizationTopic(organizationId: string, topicId: string, params: any = {}): Observable<{ data: Topic, meta: any }> {
    return this.apiService.put(`organizations/${organizationId}/topics/${topicId}`, params);
  }

  /**
   * Creates a new topic for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - The data to create the topic.
   * 
   * @returns {Observable<{ data: Topic, meta: any }>}
   */
  createOrganizationTopic(organizationId: string, params: any = {}): Observable<{ data: Topic, meta: any }> {
    return this.apiService.post(`organizations/${organizationId}/topics`, params);
  }

  /**
   * Deletes the specified topic for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} topicId - The ID of the topic to be deleted.
   * 
   * @returns {Observable<any>}
   */
  deleteOrganizationTopic(organizationId: string, topicId: string): Observable<any> {
    return this.apiService.delete(`organizations/${organizationId}/topics/${topicId}`);
  }

  /**
   * Attach the specified topics for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string[]} topicIds - The IDs of the topics.
   * @param {object} params - The data to update the topic.
   * 
   * @returns {Observable<any>}
   */
  attachOrganizationTopic(organizationId: string, topicIds: string[], params: any = {}): Observable<{ data: Topic, meta: any }> {
    return this.apiService.put(`organizations/${organizationId}/topics/attach`, { topic_ids: topicIds, ...params });
  }

  /**
   * Detach the specified topics for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string[]} topicIds - The IDs of the topics.
   * @param {object} params - The data to update the topic.
   * 
   * @returns {Observable<any>}
   */
  detachOrganizationTopic(organizationId: string, topicIds: string[], params: any = {}): Observable<{ data: Topic, meta: any }> {
    return this.apiService.put(`organizations/${organizationId}/topics/detach`, { topic_ids: topicIds, ...params });
  }

}
