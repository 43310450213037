import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Currency } from '../models/currency';

@Injectable()
export class CurrencyService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of currencies.
   *
   * @param {object} params - Optional parameters to filter currencies.
   * 
   * @returns {Observable<{ data: Currency[], meta: any }>}
   */
  getCurrencies(params: any = {}): Observable<{ data: Currency[], meta: any }> {
    return this.apiService
      .get(`currencies`, params);
  }

}
