import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { RiterzCoreConfiguration } from '../riterz-core.configuration';
import { CookieService } from './cookie.service';

@Injectable()
export class ApiService {

  constructor(
    private readonly configuration: RiterzCoreConfiguration,
    private cookieService: CookieService,
    private http: HttpClient
  ) { }

  private formatErrors(error: any) {
    return throwError(error.error);
  }
  
  /**
   * @param  {string} path Path from API root, it should not begin with / 
   * @param  {any} [params] Query params
   * 
   * @returns {Observable<any>}
   */
  get(path: string, params: any = {}): Observable<any> {
    const token = this.cookieService.get(this.configuration.accessTokenName);

    const headers: any = {};
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    headers['Content-Type'] = 'application/json';

    return this.http
      .get(`${this.configuration.apiRoot}${path}`, { params, headers })
      .pipe(catchError(this.formatErrors));
  }
  
  /**
   * @param  {string} path Path from API root, it should not begin with / 
   * @param  {any} [body] Body params
   * 
   * @returns {Observable<any>}
   */
  put(path: string, body: any = {}): Observable<any> {
    const token = this.cookieService.get(this.configuration.accessTokenName);

    const headers: any = {};
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    headers['Content-Type'] = 'application/json';

    return this.http
      .put(`${this.configuration.apiRoot}${path}`, JSON.stringify(body), { headers })
      .pipe(catchError(this.formatErrors));
  }
  
  /**
   * @param  {string} path Path from API root, it should not begin with / 
   * @param  {any} [body] Body params
   * 
   * @returns {Observable<any>}
   */
  patch(path: string, body: any = {}): Observable<any> {
    const token = this.cookieService.get(this.configuration.accessTokenName);

    const headers: any = {};
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    headers['Content-Type'] = 'application/json';

    return this.http
      .patch(`${this.configuration.apiRoot}${path}`, JSON.stringify(body), { headers })
      .pipe(catchError(this.formatErrors));
  }
  
  /**
   * @param  {string} path Path from API root, it should not begin with / 
   * @param  {any} [body]
   * @param  {any} [headers]
   * 
   * @returns {Observable<any>}
   */
  post(path: string, body: any = {}, headers: any = {}): Observable<any> {
    const token = this.cookieService.get(this.configuration.accessTokenName);
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    headers['Content-Type'] = 'application/json';

    return this.http
      .post(`${this.configuration.apiRoot}${path}`, JSON.stringify(body), { headers })
      .pipe(catchError(this.formatErrors));
  }
  
  /**
   * @param  {string} path Path from API root, it should not begin with / 
   * @param  {any} [params]
   * 
   * @returns {Observable<any>}
   */
  delete(path: string, params?: any): Observable<any> {
    const token = this.cookieService.get(this.configuration.accessTokenName);
    
    const headers: any = {};
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    headers['Content-Type'] = 'application/json';

    return this.http
      .delete(`${this.configuration.apiRoot}${path}`, { params, headers })
      .pipe(catchError(this.formatErrors));
  }
  
  /**
   * @param  {string} path Path from API root, it should not begin with / 
   * @param  {any} [body]
   * @param  {any} [headers]
   * 
   * @returns {Observable<any>}
   */
  upload(path: string, body: any = {}, headers: any = {}): Observable<any> {
    const token = this.cookieService.get(this.configuration.accessTokenName);
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    // Transform the body to form data
    const formData = new FormData();
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        formData.append(key, body[key]);
      }
    }

    return this.http
      .post(`${this.configuration.apiRoot}${path}`, formData, { headers })
      .pipe(catchError(this.formatErrors));
  }

  /**
   * @param  {string} path Path from API root, it should not begin with / 
   * @param  {string} filename File name with extension
   * @param  {any} [params]
   * 
   * @returns {Observable<any>}
   */
  download(path: string, filename: string, params?: any): Observable<any> {
    const token = this.cookieService.get(this.configuration.accessTokenName);
    
    const headers: any = {};
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return this.http
      .get(`${path}`, { params, headers, responseType: 'arraybuffer' })
      .pipe(
        map((response: any) => {
          const [name, extension] = filename.split('.');
          let type = 'application/octet-stream';

          if (extension === 'xlsx') {
            type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          } else if (extension === 'xls') {
            type = 'application/vnd.ms-excel';
          } else if (extension === 'csv') {
            type = 'text/csv';
          } else if (extension === 'pdf') {
            type = 'application/pdf';
          } 

          const file = new Blob([response], { type });
          const fileURL = URL.createObjectURL(file);
          const a = document.createElement('a');
          a.href = fileURL;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }),
        catchError(this.formatErrors)
      );
  }

}
