import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Topic } from '../models/topic';

@Injectable()
export class TopicService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * @param {any} [params] Params to filter countries
   * 
   * @returns {Observable<{ data: Topic[], meta: any }>}
   */
  getPublicTopics(params: any = {}): Observable<{ data: Topic[], meta: any }> {
    return this.apiService
      .get(`topics`, params);
  }

}
