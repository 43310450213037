import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Channel } from '../models/channel';
import { Observable } from 'rxjs';

@Injectable()
export class ChannelService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of channels.
   * 
   * @param {object} params - Optional parameters to filter channels.
   * 
   * @returns {Observable<{ data: Channel[], meta: any }>}
   */
  getChannels(params: any = {}): Observable<{ data: Channel[], meta: any }> {
    return this.apiService.get(`channels`, params);
  }

  /**
   * Retrieves the specified channel.
   * 
   * @param {string} channelId - The ID of the channel.
   * @param {object} params - Optional parameters modify the response.
   * 
   * @returns {Observable<Channel>}
   */
  getChannel(channelId: string, params: any = {}): Observable<Channel> {
    return this.apiService.get(`channels/${channelId}`, params);
  }

  /**
   * Updates the specified channel.
   * 
   * @param {string} channelId - The ID of the channel.
   * @param {object} params - The data to update the channel.
   * 
   * @returns {Observable<{ data: Channel, meta: any }>}
   */
  updateChannel(channelId: string, params: any = {}): Observable<{ data: Channel, meta: any }> {
    return this.apiService.put(`channels/${channelId}`, params);
  }

  /**
   * Creates a new channel.
   * 
   * @param {object} params - The data to create the channel.
   * 
   * @returns {Observable<{ data: Channel, meta: any }>}
   */
  createChannel(params: any = {}): Observable<{ data: Channel, meta: any }> {
    return this.apiService.post(`channels`, params);
  }

  /**
   * Deletes the specified channel.
   * 
   * @param {string} channelId - The ID of the channel to be deleted.
   * 
   * @returns {Observable<any>}
   */
  deleteChannel(channelId: string): Observable<any> {
    return this.apiService.delete(`channels/${channelId}`);
  }
  /**
   * Retrieves a list of channels.
   * 
   * @param {object} params - Optional parameters to filter channels.
   * 
   * @returns {Observable<{ data: Channel[], meta: any }>}
   */
  getPublicChannels(params: any = {}): Observable<{ data: Channel[], meta: any }> {
    return this.apiService.get(`public/channels`, params);
  }

}
