import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Country } from '../models/country';

@Injectable()
export class CountryService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of countries.
   * 
   * @param {any} [params] - Optional parameters to filter countries
   * 
   * @returns {Observable<{ data: Country[], meta: any }>}
   */
  getCountries(params: any = {}): Observable<{ data: Country[], meta: any }> {
    return this.apiService
      .get(`countries`, params);
  }

}
