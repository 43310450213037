import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { OrganizationProvider } from '../models/organization-provider';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationProviderService {

  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Retrieves a list of providers for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - Optional parameters to filter providers.
   * 
   * @returns {Observable<{ data: OrganizationProvider[], meta: any }>}
   */
  getOrganizationProviders(organizationId: string, params: any = {}): Observable<{ data: OrganizationProvider[], meta: any }> {
    return this.apiService.get(`organizations/${organizationId}/providers`, params);
  }

  /**
   * Retrieves the specified provider.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} providerId - The ID of the provider.
   * @param {object} params - Optional parameters modify the response.
   * 
   * @returns {Observable<{ data: OrganizationProvider, meta: any }>}
   */
  getOrganizationProvider(organizationId: string, providerId: string, params: any = {}): Observable<{ data: OrganizationProvider, meta: any }> {
    return this.apiService.get(`organizations/${organizationId}/providers/${providerId}`, params);
  }

  /**
   * Updates the specified provider for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} providerId - The ID of the provider.
   * @param {object} params - The data to update the provider.
   * 
   * @returns {Observable<{ data: OrganizationProvider, meta: any }>}
   */
  updateOrganizationProvider(organizationId: string, providerId: string, params: any = {}): Observable<{ data: OrganizationProvider, meta: any }> {
    return this.apiService.put(`organizations/${organizationId}/providers/${providerId}`, params);
  }

  /**
   * Creates a new provider for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {object} params - The data to create the provider.
   * 
   * @returns {Observable<{ data: OrganizationProvider, meta: any }>}
   */
  createOrganizationProvider(organizationId: string, params: any = {}): Observable<{ data: OrganizationProvider, meta: any }> {
    return this.apiService.post(`organizations/${organizationId}/providers`, params);
  }

  /**
   * Deletes the specified provider for the specified organization.
   * 
   * @param {string} organizationId - The ID of the organization.
   * @param {string} providerId - The ID of the provider to be deleted.
   * 
   * @returns {Observable<any>}
   */
  deleteOrganizationProvider(organizationId: string, providerId: string): Observable<any> {
    return this.apiService.delete(`organizations/${organizationId}/providers/${providerId}`);
  }

  /**
   * Retrieves a list of providers types.
   * 
   * @param {object} params - Optional parameters to filter providers.
   * 
   * @returns {Observable<{ data: any[], meta: any }>}
   */
  getOrganizationProviderTypes(params: any = {}): Observable<{ data: any[], meta: any }> {
    return this.apiService.get(`public/provider-types`, params);
  }

}
