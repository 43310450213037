import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { RiterzCoreConfiguration } from '../riterz-core.configuration';
import { isPlatformBrowser } from '@angular/common';

declare var gtag: any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private readonly configuration: RiterzCoreConfiguration,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((e: NavigationEnd) => {
        if (typeof gtag === 'function') {
          gtag('js', new Date());
          gtag('config', this.configuration.googleAnalyticsId);          
        }
      });
  }

  init() {
    if (isPlatformBrowser(this.platformId)) {
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-HBBEBD89E6';
      script.async = true;
      document.getElementsByTagName('head')[0].appendChild(script);

      const gtagEl = document.createElement('script');
      const gtagBody = document.createTextNode(`
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
      `);
      gtagEl.appendChild(gtagBody);
      document.body.appendChild(gtagEl);
    }
  }
  
  logEvent(event: string, category: string, label: string, value: any) {
    gtag('event', event, {
      event_category: category,
      event_label: label,
      value: value
    });
  
    console.log('gtag event captured...');
  }

}